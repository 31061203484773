import axios from 'axios';

const url = process.env.VUE_APP_ROOT_API ;

export default {

  getAllTransactionTypes(){
    return axios.get(url + '/opay-api/antifraud/get-transaction-types').then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  createTransactionType(params){
    return axios.post(url + '/opay-api/antifraud/create-transaction-type',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  updateTransactionType(params){
    return axios.post(url + '/opay-api/antifraud/update-transaction-type',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  removeTransactionTypeById(id){
    return axios.get(url + '/opay-api/antifraud/remove-transaction-type/'+id).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getTransactionTypeById(id){
    return axios.get(url + '/opay-api/antifraud/get-transaction-type/'+id).then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  getAllRule(){
    return axios.get(url + '/opay-api/antifraud/get-rules').then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },
  
  getClientTransactions(params){
    return axios.post(url + '/opay-api/antifraud/get-client-transactions',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },
  
  getRuleByTransactionType(code){
    return axios.get(url + '/opay-api/antifraud/get-transaction-rules/'+ code).then(response =>response.data.payload)
    .catch((error) => { return error.response });
  },

  createTransactionRule(params){
    return axios.post(url + '/opay-api/antifraud/create-transaction-rule',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getTransactionRuleById(id){  
    return axios.get(url + '/opay-api/antifraud/get-transaction-rule/'+ id).then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  updateTransactionRule(params){
    return axios.post(url + '/opay-api/antifraud/update-transaction-rule',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  removeTransactionRule(id){
    return axios.get(url + '/opay-api/antifraud/remove-transaction-rule/'+id).then(response =>response.data)
    .catch((error) => { return error.response });
  },

};
